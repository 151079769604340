import React from "react";
import { createRoot } from "react-dom/client";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import App from "./components/App";

import "./reset.css";
import "./styles.css";

const firebaseConfig = {
    apiKey: "AIzaSyCIon4GrHVXKqAtyrxQOfLVCyoTEah_M5g",
    authDomain: "project-base-firestore-406706.firebaseapp.com",
    projectId: "project-base-firestore-406706",
    storageBucket: "project-base-firestore-406706.appspot.com",
    messagingSenderId: "760407907420",
    appId: "1:760407907420:web:71ab4de0fb957c2bb79a99",
    measurementId: "G-LHWPE1PEW9"
  };
  
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = createRoot(document.querySelector("#root"));
root.render(<App />);
